<template>
  <header id="page-header" class="d-print-none">
    <h1 class="h1">Start Your Action Plan</h1>
  </header>
  <div class="container pt-4 d-print-none">
    <h2 class="h2">Action Planner</h2>
  </div>
  <div class="container">
    <h1 class="d-none d-print-block mt-5 mb-0">Action Plan</h1>
    <div class="row py-2">
      <h6 class="h6 fw-bold">Our common agenda</h6>
      <p class="p">{{ commonAgendaStatement }}</p>
    </div>
    <div class="row py-2">
      <h6 class="h6 fw-bold">We focus on serving</h6>
      <p class="p">{{ commonAgendaWeServe }}</p>
    </div>
    <div class="row py-2">
      <h6 class="h6 fw-bold">We do this by</h6>
      <ul v-for="(record, i) in commonAgendaHowWeDoThis" :key="i" class="ps-4 ms-4">
        <li v-if="record.col1">{{ record.col1 }}</li>
      </ul>
    </div>
    <h3 class="h3 mb-4">Strategies and Action Items</h3>
    <div class="card mv-form-card my-2 p-3" v-for="(goal, i) in strategicGoals" :key="i">
      <!-- Strategy and Action Item Form Header -->
      <div class="mb-4">
        <h4 class="h4 visually-hidden">Strategy</h4>
        <button class="btn btn-outline mv-icon-btn btn-sm float-end d-print-none" aria-label="Delete Strategic Goal" @click="deleteStrategicGoal(i);">
          <i class="bi bi-trash"></i>
        </button>
      </div>
      <!-- Strategy Form Section -->
      <div class="row mb-2">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('strategy' + i)">Strategy:</label>
          <input
            v-model="strategicGoals[i].strategy"
            @blur="updateStrategicGoalProp(i, 'strategy', strategicGoals[i].strategy);"
            placeholder="Strategy..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="('strategy' + i)"
            :name="('strategy' + i)">
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].strategy) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('strategy' + i)">Type:</label>
          <select
            @blur="updateStrategicGoalProp(i, 'type', strategicGoals[i].type)"
            v-model="strategicGoals[i].type"
            class="form-select form-select-sm mv-input__select d-print-none"
            :id="('type' + i)"
            :name="('type' + i)">
            <option selected disabled value="0">Type...</option>
            <option v-for="(option, optionVal) in typeOpts" :key="optionVal">{{ option }}</option>
          </select>
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].type) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('ledBy' + i)">Led By:</label>
          <input
            v-model="strategicGoals[i].ledBy"
            @blur="updateStrategicGoalProp(i, 'ledBy', strategicGoals[i].ledBy);"
            placeholder="Led By..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="('ledBy' + i)"
            :name="('ledBy' + i)">
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].ledBy) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('supportedBy' + i)">Supported By:</label>
          <input
            v-model="strategicGoals[i].supportedBy"
            @blur="updateStrategicGoalProp(i, 'supportedBy', strategicGoals[i].supportedBy);"
            placeholder="Supported By..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="('supportedBy' + i)"
            :name="('supportedBy' + i)">
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].supportedBy) }}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('resultsIn' + i)">Results in:</label>
          <select
            @blur="updateStrategicGoalProp(i, 'type', strategicGoals[i].resultsIn)"
            v-model="strategicGoals[i].resultsIn"
            class="form-select form-select-sm mv-input__select d-print-none"
            :id="('resultsIn' + i)"
            :name="('resultsIn' + i)">
            <option selected disabled value="0">Results in...</option>
            <option v-for="(option, optionVal) in resultsInOpts" :key="optionVal">{{ option }}</option>
          </select>
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].resultsIn) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('initiatedBy' + i)">Initiated By:</label>
          <input
            v-model="strategicGoals[i].initiatedBy"
            @blur="updateStrategicGoalProp(i, 'initiatedBy', strategicGoals[i].initiatedBy);"
            placeholder="Initiated By..."
            class="form-control mv-input__input d-print-none"
            type="date"
            :id="('initiatedBy' + i)"
            :name="('initiatedBy' + i)">
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].initiatedBy) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('completedBy' + i)">Completed By:</label>
          <input
            v-model="strategicGoals[i].completedBy"
            @blur="updateStrategicGoalProp(i, 'completedBy', strategicGoals[i].completedBy);"
            placeholder="Completed By..."
            class="form-control mv-input__input d-print-none"
            type="date"
            :id="('completedBy' + i)"
            :name="('completedBy' + i)">
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].completedBy) }}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('evidenceBased' + i)">Evidence Based:</label>
          <select
            @blur="updateStrategicGoalProp(i, 'evidenceBased', strategicGoals[i].evidenceBased)"
            v-model="strategicGoals[i].evidenceBased"
            class="form-select form-select-sm mv-input__select d-print-none"
            :id="('evidenceBased' + i)"
            :name="('evidenceBased' + i)">
            <option selected disabled value="0">Evidence Based...</option>
            <option v-for="(option, optionVal) in evidenceBasedOpts" :key="optionVal">{{ option }}</option>
          </select>
          <p class="d-none d-print-block">
            {{ getValueWithDefault(strategicGoals[i].evidenceBased) }}
          </p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('citations' + i)">Citation(s):</label>
          <textarea
            v-model="strategicGoals[i].citations"
            @blur="updateStrategicGoalProp(i, 'citations', strategicGoals[i].citations);"
            placeholder="Citation(s)..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="('citations' + i)"
            :name="('citations' + i)"></textarea>
          <p class="d-none d-print-block">{{ getValueWithDefault(strategicGoals[i].citations) }}</p>
        </div>
      </div>
      <!-- Action Items Form Section -->
      <h4 class="h4 visually-hidden">Action Items</h4>
      <div class="row my-1 action-item p-1" v-for="(item, itemKey) in goal.actionItems" :key="itemKey">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('name' + i + '-' + itemKey)">Action Item:</label>
          <input
            v-model="item.name"
            @blur="updateActionPlanItemProp(i, itemKey, 'name', item.name);"
            placeholder="Action Item..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="('name' + i + '-' + itemKey)"
            :name="('name' + i + '-' + itemKey)">
          <p class="d-none d-print-block">{{ getValueWithDefault(item.name) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('initiatedBy' + i + '-' + itemKey)">Initiated By:</label>
          <input
            v-model="item.initiatedBy"
            @blur="updateActionPlanItemProp(i, itemKey, 'initiatedBy', item.initiatedBy);"
            placeholder="Initiated By..."
            class="form-control mv-input__input d-print-none"
            type="date"
            :id="('initiatedBy' + i + '-' + itemKey)"
            :name="('initiatedBy' + i + '-' + itemKey)">
          <p class="d-none d-print-block">{{ getValueWithDefault(item.initiatedBy) }}</p>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="('completedBy' + i + '-' + itemKey)">Completed By:</label>
          <input
            v-model="item.completedBy"
            @blur="updateActionPlanItemProp(i, itemKey, 'completedBy', item.completedBy);"
            placeholder="Completed By..."
            class="form-control mv-input__input d-print-none"
            type="date"
            :id="('completedBy' + i + '-' + itemKey)"
            :name="('completedBy' + i + '-' + itemKey)">
          <p class="d-none d-print-block">{{ getValueWithDefault(item.completedBy) }}</p>
        </div>
        <div class="col-1 d-flex align-items-center justify-content-center d-print-none">
          <button class="btn btn-outline btn-sm mv-icon-btn float-end d-print-none" aria-label="Delete Action Item" @click="deleteActionPlanItem(i, itemKey);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addActionPlanItem(i)">+ Action Item</button>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-dark me-2 d-print-none" onclick="window.print();">Print Action Plan</button>
      <button class="btn btn-sm btn-secondary d-print-none" @click="addStrategicGoal()">+ Strategy</button>
    </div>
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <a
          class="btn btn-primary float-start d-print-none"
          href="/common-agenda">
          <i class="bi bi-chevron-left"></i>
        </a>
        <router-link
          tag="button"
          class="btn btn-primary float-end d-print-none"
          to="/dashboard">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'ActionPlanPage',
  setup() {
    const store = useStore();

    return {
      store,
      commonAgendaStatement: computed({ get: () => store.state.measurement.commonAgendaStatement }),
      commonAgendaWeServe: computed({ get: () => store.state.measurement.commonAgendaWeServe }),
      commonAgendaHowWeDoThis: computed(() => { return store.state.measurement.listRecords.doby; }),
      strategicGoals: computed(() => store.state.actionPlan.strategicGoals),
      actionPlanItems: computed(() => store.state.actionPlan.goalActionItems),
      typeOpts: computed(() => store.state.actionPlan.typeOpts),
      resultsInOpts: computed(() => store.state.actionPlan.resultsInOpts),
      evidenceBasedOpts: computed(() => store.state.actionPlan.evidenceBasedOpts),
      addStrategicGoal: () => store.dispatch('actionPlan/addStrategicGoal'),
      deleteStrategicGoal: (id) => store.dispatch('actionPlan/deleteStrategicGoal', { id }),
      updateStrategicGoalProp: (id, prop, value) => store.dispatch('actionPlan/updateStrategicGoalProp', { id, prop, value }),
      addActionPlanItem: (goalId) => store.dispatch('actionPlan/addActionPlanItem', { goalId }),
      deleteActionPlanItem: (goalId, id) => store.dispatch('actionPlan/deleteActionPlanItem', { goalId, id }),
      updateActionPlanItemProp: (goalId, id, prop, value) => store.dispatch('actionPlan/updateActionPlanItemProp', { goalId, id, prop, value }),
      getValueWithDefault: (value) => {
        if (value === null || value === 0 || value === undefined) {
          return 'Not entered...';
        }
        return value;
      },
    };
  },
};
</script>
<style lang="scss" scoped>
h2, h3 {
  font-weight: 700;
  font-size: 1.25rem;
}
.action-item {
  &:hover {
    background: $light;
  }
}
.mv-form-card {
  background: ghostwhite;
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
.mv-input {
  max-width: $input-group-max-width;
  width: $input-group-width;
  &__label {
    color: $input-group-label-text-color;
    font-size: $input-group-label-font-size;
    font-weight: $input-group-label-font-weight;
    order:1;
  }
  &__input,
  &__select {
    border-radius: 3px;
    border-width: $input-group-input-border-width;
    color: $input-group-input-text-color;
    font-size: $form-control-input-font-size-base;
    order:2;
    &:focus {
      border-color: $input-group-input-border-color-focus;
      box-shadow: none;
      outline: none;
    }
  }
  &__select {
    padding: 6px 12px;
  }
}
</style>
